const CircularProgress = ({ percentage }) => {
    const size = 55;
    const strokeWidth = 6;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
  
    const getColor = (percent) => percent === 0 ? '#d3d3d3' : percent === 100 ? '#ffd700' : '#58cc02';
    const getTextColor = (percent) => percent === 0 ? '#808080' : percent === 100 ? '#d4af37' : '#58cc02';
  
    const circleColor = getColor(percentage);
    const textColor = getTextColor(percentage);
  
    return (
      <div style={{ width: `${size}px`, height: `${size}px` }} className="relative flex-shrink-0">
        <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
          <circle
            className="text-gray-200"
            strokeWidth={strokeWidth}
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          <circle
            className="transition-all duration-300"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke={circleColor}
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm font-bold" style={{ color: textColor }}>
            {percentage}%
          </span>
        </div>
      </div>
    );
  };

  
export default CircularProgress;