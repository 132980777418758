import React, { useState, useEffect } from 'react';
import { Heart, XCircle, Trophy, X, PlusCircle } from 'lucide-react';


const QuizRulesExplanation = () => {
    const explanationItems = [
      { 
        icon: Heart, 
        text: "لديك 10 قلوب. كل خطأ يُفقدك قلبًا واحدًا.", 
        color: "text-red-500",
        bgColor: "bg-red-100" 
      },
     
      { 
        icon: XCircle, 
        text: "تنتهي اللعبة إذا فقدت جميع القلوب.", 
        color: "text-yellow-500",
        bgColor: "bg-yellow-100" 
      },
      { 
        icon: Trophy, 
        text: "أجب على كل الأسئلة بشكل صحيح للفوز!", 
        color: "text-green-500",
        bgColor: "bg-green-100" 
      },
    ];
  
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
      const explanationClosed = localStorage.getItem('quizExplanationClosed');
      if (explanationClosed === 'true') {
        setIsVisible(false);
      }
    }, []);
  
    const handleClose = () => {
      setIsVisible(false);
      localStorage.setItem('quizExplanationClosed', 'true');
    };
  
    if (!isVisible) return null;
  
    return (
      <div className="bg-white p-6 rounded-xl shadow-md max-w-md mx-auto border border-gray-200 mb-6 relative">
        <button 
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
          aria-label="Close explanation"
        >
          <X size={20} />
        </button>
        <h3 className="text-2xl font-bold text-[#4b4b4b] mb-6 text-center">كيف تلعب</h3>
        <ul className="space-y-6">
          {explanationItems.map(({ icon: Icon, text, color, bgColor }, index) => (
            <li key={index} className={`${bgColor} p-4 rounded-lg flex items-start space-x-4 rtl:space-x-reverse`}>
              <div className={`${color} ${bgColor} p-2 rounded-full`}>
                <Icon className="w-8 h-8" />
              </div>
              <div>
                <p className={`text-lg font-semibold ${color}`}>{text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default QuizRulesExplanation;