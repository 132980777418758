import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, where, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Edit, Save, X, Plus, ChevronDown, Loader, BookOpen, GraduationCap, Book } from 'lucide-react';

const Button = ({ children, ...props }) => (
  <button
    {...props}
    className={`p-3 rounded-xl text-base font-semibold flex items-center justify-center transition-all duration-200 ease-in-out ${props.className}`}
  >
    {children}
  </button>
);

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-6 max-w-lg w-full">
        <button onClick={onClose} className="float-right text-gray-600 hover:text-gray-800">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center p-4">
    <Loader className="animate-spin text-[#58cc02]" size={24} />
  </div>
);

const Dashboard = () => {
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loadingGrades, setLoadingGrades] = useState(true);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingLessons, setLoadingLessons] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [newItemName, setNewItemName] = useState('');
  const [newLessonContent, setNewLessonContent] = useState('');

  useEffect(() => {
    fetchGrades();
  }, []);

  useEffect(() => {
    if (selectedGrade) {
      fetchSubjects(selectedGrade.id);
      setSelectedSubject(null);
    }
  }, [selectedGrade]);

  useEffect(() => {
    if (selectedSubject) {
      fetchLessons(selectedSubject.id);
    }
  }, [selectedSubject]);

  const fetchGrades = async () => {
    setLoadingGrades(true);
    try {
      const gradesSnapshot = await getDocs(collection(db, 'grades'));
      const gradesData = gradesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGrades(gradesData);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
    setLoadingGrades(false);
  };

  const fetchSubjects = async (gradeId) => {
    setLoadingSubjects(true);
    try {
      const subjectsSnapshot = await getDocs(query(collection(db, 'subjects'), where('gradeId', '==', gradeId)));
      const subjectsData = subjectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSubjects(subjectsData);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
    setLoadingSubjects(false);
  };

  const fetchLessons = async (subjectId) => {
    setLoadingLessons(true);
    try {
      const lessonsSnapshot = await getDocs(query(collection(db, 'lessons'), where('subjectId', '==', subjectId)));
      const lessonsData = lessonsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLessons(lessonsData);
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
    setLoadingLessons(false);
  };

  const handleAdd = async (type) => {
    if (newItemName.trim()) {
      let newItem;
      switch (type) {
        case 'grade':
          newItem = { name: newItemName };
          await addDoc(collection(db, 'grades'), newItem);
          fetchGrades();
          break;
        case 'subject':
          if (selectedGrade) {
            newItem = { name: newItemName, gradeId: selectedGrade.id };
            await addDoc(collection(db, 'subjects'), newItem);
            fetchSubjects(selectedGrade.id);
          }
          break;
        case 'lesson':
          if (selectedSubject && newLessonContent.trim()) {
            newItem = { name: newItemName, content: newLessonContent, subjectId: selectedSubject.id };
            await addDoc(collection(db, 'lessons'), newItem);
            fetchLessons(selectedSubject.id);
          }
          break;
      }
      setNewItemName('');
      setNewLessonContent('');
      setActiveModal(null);
    }
  };

  const handleUpdate = async (type) => {
    if (editItem && editItem.name.trim()) {
      switch (type) {
        case 'grade':
          await updateDoc(doc(db, 'grades', editItem.id), { name: editItem.name });
          fetchGrades();
          break;
        case 'subject':
          await updateDoc(doc(db, 'subjects', editItem.id), { name: editItem.name });
          fetchSubjects(selectedGrade.id);
          break;
        case 'lesson':
          if (editItem.content.trim()) {
            await updateDoc(doc(db, 'lessons', editItem.id), { name: editItem.name, content: editItem.content });
            fetchLessons(selectedSubject.id);
          }
          break;
      }
      setEditItem(null);
      setActiveModal(null);
    }
  };

  const renderModal = () => {
    const isEditing = editItem !== null;
    let title, content;

    switch (activeModal) {
      case 'grade':
        title = isEditing ? 'Edit Grade' : 'Add New Grade';
        content = (
          <input
            type="text"
            value={isEditing ? editItem.name : newItemName}
            onChange={(e) => isEditing ? setEditItem({...editItem, name: e.target.value}) : setNewItemName(e.target.value)}
            className="w-full border-2 border-[#e5e5e5] rounded-xl px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-[#58cc02] focus:border-[#58cc02]"
            placeholder="Grade Name"
          />
        );
        break;
      case 'subject':
        title = isEditing ? 'Edit Subject' : 'Add New Subject';
        content = (
          <input
            type="text"
            value={isEditing ? editItem.name : newItemName}
            onChange={(e) => isEditing ? setEditItem({...editItem, name: e.target.value}) : setNewItemName(e.target.value)}
            className="w-full border-2 border-[#e5e5e5] rounded-xl px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-[#58cc02] focus:border-[#58cc02]"
            placeholder="Subject Name"
          />
        );
        break;
      case 'lesson':
        title = isEditing ? 'Edit Lesson' : 'Add New Lesson';
        content = (
          <>
            <input
              type="text"
              value={isEditing ? editItem.name : newItemName}
              onChange={(e) => isEditing ? setEditItem({...editItem, name: e.target.value}) : setNewItemName(e.target.value)}
              className="w-full border-2 border-[#e5e5e5] rounded-xl px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-[#58cc02] focus:border-[#58cc02]"
              placeholder="Lesson Name"
            />
            <textarea
              value={isEditing ? editItem.content : newLessonContent}
              onChange={(e) => isEditing ? setEditItem({...editItem, content: e.target.value}) : setNewLessonContent(e.target.value)}
              className="w-full border-2 border-[#e5e5e5] rounded-xl px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-[#58cc02] focus:border-[#58cc02]"
              placeholder="Lesson Content"
              rows="4"
            />
          </>
        );
        break;
      default:
        return null;
    }

    return (
      <Modal isOpen={activeModal !== null} onClose={() => { setActiveModal(null); setEditItem(null); }}>
        <h2 className="text-2xl font-bold mb-4 text-[#3c3c3c]">{title}</h2>
        {content}
        <div className="flex justify-end gap-2">
          <Button onClick={() => { setActiveModal(null); setEditItem(null); }} className="bg-gray-200 text-[#3c3c3c] hover:bg-gray-300">
            Cancel
          </Button>
          <Button onClick={() => isEditing ? handleUpdate(activeModal) : handleAdd(activeModal)} className="bg-[#58cc02] text-white hover:bg-[#46a302]">
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-12 text-[#3c3c3c]">Admin Dashboard</h1>
      
      {/* Grades Section */}
      <div className="mb-12 bg-white rounded-2xl shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-[#3c3c3c]">Grades</h2>
          <Button onClick={() => setActiveModal('grade')} className="bg-[#58cc02] text-white hover:bg-[#46a302]">
            <Plus size={18} className="mr-2" /> Add Grade
          </Button>
        </div>
        {loadingGrades ? (
          <LoadingSpinner />
        ) : (
          <div className="flex flex-wrap gap-4">
            {grades.map(grade => (
              <div
                key={grade.id}
                className={`flex items-center justify-between p-4 rounded-xl cursor-pointer transition-all duration-200 ${
                  selectedGrade?.id === grade.id
                    ? 'bg-white border-2 border-[#58cc02]'
                    : 'bg-[#f7f7f7] hover:bg-[#e5e5e5]'
                }`}
                onClick={() => setSelectedGrade(grade)}
              >
                <div className="flex items-center">
                  <GraduationCap size={24} className="mr-3 text-[#58cc02]" />
                  <span className="text-lg font-semibold text-[#3c3c3c]">{grade.name}</span>
                </div>
                <Button
                  onClick={(e) => { e.stopPropagation(); setEditItem(grade); setActiveModal('grade'); }}
                  className="ml-4 bg-[#1cb0f6] text-white hover:bg-[#0c9de0]"
                >
                  <Edit size={18} />
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Subjects Section */}
      {selectedGrade && (
        <div className="mb-12 bg-white rounded-2xl shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-[#3c3c3c]">Subjects for {selectedGrade.name}</h2>
            <Button onClick={() => setActiveModal('subject')} className="bg-[#58cc02] text-white hover:bg-[#46a302]">
              <Plus size={18} className="mr-2" /> Add Subject
            </Button>
          </div>
          {loadingSubjects ? (
            <LoadingSpinner />
          ) : (
            <div className="flex flex-wrap gap-4">
              {subjects.map(subject => (
                <div
                  key={subject.id}
                  className={`flex items-center justify-between p-4 rounded-xl cursor-pointer transition-all duration-200 ${
                    selectedSubject?.id === subject.id
                      ? 'bg-white border-2 border-[#58cc02]'
                      : 'bg-[#f7f7f7] hover:bg-[#e5e5e5]'
                  }`}
                  onClick={() => setSelectedSubject(subject)}
                >
                  <div className="flex items-center">
                    <Book size={24} className="mr-3 text-[#58cc02]" />
                    <span className="text-lg font-semibold text-[#3c3c3c]">{subject.name}</span>
                  </div>
                  <Button
                    onClick={(e) => { e.stopPropagation(); setEditItem(subject); setActiveModal('subject'); }}
                    className="ml-4 bg-[#1cb0f6] text-white hover:bg-[#0c9de0]"
                  >
                    <Edit size={18} />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Lessons Section */}
      {selectedSubject && (
        <div className="bg-white rounded-2xl shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-[#3c3c3c]">Lessons for {selectedSubject.name}</h2>
            <Button onClick={() => setActiveModal('lesson')} className="bg-[#58cc02] text-white hover:bg-[#46a302]">
              <Plus size={18} className="mr-2" /> Add Lesson
            </Button>
          </div>
          {loadingLessons ? (
            <LoadingSpinner />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {lessons.map(lesson => (
              <div key={lesson.id} className="border-2 border-[#e5e5e5] p-5 rounded-xl hover:shadow-md transition-shadow duration-200">
                <h3 className="font-semibold text-xl text-[#3c3c3c] mb-3">{lesson.name}</h3>
                <p className="text-sm text-[#4b4b4b] mb-4">{lesson.content.substring(0, 100)}...</p>
                <Button
                  onClick={() => { setEditItem(lesson); setActiveModal('lesson'); }}
                  className="w-full bg-[#1cb0f6] text-white hover:bg-[#0c9de0]"
                >
                  <Edit size={18} className="mr-2" /> Edit Lesson
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    )}

    {renderModal()}
  </div>
);
};

export default Dashboard;