import React, { useState, useEffect,useRef, useCallback } from 'react';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { PlayCircle, ChevronDown, ChevronRight, Sun, ChevronUp, Zap, ArrowRight, RefreshCw, Plus, Lightbulb, Flag, Star, TrendingUp, PlusCircle, Book, GraduationCap, FileType, Trophy, XCircle, AlertCircle, CheckCircle2, Heart, X, Clock, Target } from 'lucide-react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import CircularProgress from '../components/CircularProgress';
import QuizRulesExplanation from '../components/QuizRulesExplanation';
import { useSearchParams } from 'react-router-dom';
import ToggleSwitch from '../components/ToggleSwitch';


const Question = ({ question, index, isGameOver, isTranslated, onToggleTranslation, questionProgress }) => {
  const [answerHtml, setAnswerHtml] = useState('');

  useEffect(() => {
    const html = question?.answer?.text.replace(/\{SELECT (\w+)\}/g, (_, key) => {
      const { options, correct, explanation } = question.answer.blanks[key];
      
      let displayOptions = [...options];  // Create a copy of the options array
      
      // Fisher-Yates shuffle algorithm
      for (let i = displayOptions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [displayOptions[i], displayOptions[j]] = [displayOptions[j], displayOptions[i]];
      }
      
      // Ensure the correct option is included
      if (!displayOptions.includes(correct)) {
        const randomIndex = Math.floor(Math.random() * displayOptions.length);
        displayOptions[randomIndex] = correct;
      }
      
      // Limit to 4 options if there are more
      if (displayOptions.length > 4) {
        displayOptions = displayOptions.slice(0, 4);
        
        // Ensure the correct option is still included after slicing
        if (!displayOptions.includes(correct)) {
          const randomIndex = Math.floor(Math.random() * 4);
          displayOptions[randomIndex] = correct;
        }
      }

      return `
        <span class="inline-flex items-center align-baseline">
          <span class="relative">
            <span id="tooltip-${index}-${key}" class="tooltip absolute bottom-full left-1/2 transform -translate-x-1/2 mb-12 p-4 text-base rounded-2xl opacity-0 transition-opacity duration-300 pointer-events-none shadow-md">
           
            <span class="font-bold block mb-1 rtl">تلميح مفيد:</span>
              <span class="block text-center">${explanation}</span>
            </span>
            <select 
              id="select-${index}-${key}" 
                         class="inline-block px-3 py-2 sm:px-4 sm:py-3 mx-1 sm:mx-2 my-1 text-lg sm:text-xl md:text-xl font-semibold text-[#4b4b4b] bg-white border-2 border-[#e5e5e5] rounded-xl sm:rounded-2xl shadow-sm hover:border-gray-400 focus:outline-none focus:ring-none transition-all duration-200" 

              onchange="handleSelectChange(event, '${index}', '${key}', '${correct}')"

              >
              <option value="">اختر</option>
              ${displayOptions.map(option => `<option value="${option}">${option}</option>`).join('')}
            </select>
          </span>
        </span>      `;
    });
    setAnswerHtml(html);
  }, [question, index]);


  useEffect(() => {
     
    window.showTooltip = (id) => {
       
      const tooltip = document.getElementById(`tooltip-${id}`);
      if (tooltip) {
        tooltip.style.opacity = '1';
        tooltip.style.visibility = 'visible';
        
        const rect = tooltip.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        
        if (rect.left < 0) {
          tooltip.style.left = '0';
          tooltip.style.transform = 'translateX(0)';
        } else if (rect.right > viewportWidth) {
          tooltip.style.left = 'auto';
          tooltip.style.right = '0';
          tooltip.style.transform = 'translateX(0)';
        }
      }
    };
    window.hideTooltip = (id) => {
      const tooltip = document.getElementById(`tooltip-${id}`);
      if (tooltip) {
        tooltip.style.opacity = '0';
        setTimeout(() => {
          tooltip.style.visibility = 'hidden';
          tooltip.style.left = '50%';
          tooltip.style.right = 'auto';
          tooltip.style.transform = 'translateX(-50%)';
        }, 300);
      }
    };

    return () => {
      delete window.showTooltip;
      delete window.hideTooltip;
    };
  }, []);

  const handleToggle = () => {
    onToggleTranslation();
  };

  return (
    <div className="bg-white shadow-lg rounded-2xl sm:rounded-3xl py-4 sm:py-6 md:py-8 px-0 max-w-4xl mx-auto border-2 border-[#e5e5e5]">
      <div className="flex justify-between items-center mb-4 px-4">
        <h2 className="text-xl sm:text-2xl font-bold text-[#4b4b4b]">
          السؤال {index + 1} 
        </h2>

        {question.translatedQuestionSectionHtml && (
          <ToggleSwitch
            isOn={isTranslated}
            onToggle={handleToggle}
            label={'بالتونسي'}
            image={'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg'}
          />
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: isTranslated && question.translatedQuestionSectionHtml ? question.translatedQuestionSectionHtml : question.questionSectionHtml }} className="mb-6 sm:mb-8 bg-[#fff5d9] rounded-xl sm:rounded-2xl text-lg sm:text-2xl leading-relaxed " />
      <div className="mb-6 sm:mb-8 p-4 mx-4 sm:p-6 bg-[#f7f7f7] rounded-xl sm:rounded-2xl">
        <h3 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-5 text-[#4b4b4b] text-center">
          املأ الفراغات
        </h3>
        <div
          className="text-base sm:text-xl md:text-lg leading-loose"
          style={{ lineHeight: '2.5' }}
          dangerouslySetInnerHTML={{ __html: answerHtml }}
        />
      </div>
     
     
    </div>
  );
};


const QuizList = ({ quizzes, onPlayQuiz, savedPercentages }) => (
  <div className="max-w-4xl mx-auto mb-12 px-4">
    <h2 className="text-3xl font-bold text-[#3c3c3c] mb-8 text-center">اختر تمرينك</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {quizzes.map((quiz) => (
        <div key={quiz.id} className="bg-white rounded-2xl shadow-md overflow-hidden transition-all duration-300 border border-gray-200 hover:border-[#58cc02] hover:shadow-lg">
          {quiz.exerciseImageUrl && (
            <div className="w-full h-48 relative">
              <Zoom>
                <img src={quiz.exerciseImageUrl} alt="Exercise preview" className="absolute inset-0 w-full h-full object-cover" />
              </Zoom>
            </div>
          )}
          <div className="p-6 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-bold text-[#3c3c3c]">{quiz.name}</h3>
                <CircularProgress percentage={savedPercentages[quiz.id] || 0} size={40} />
              </div>
              <div className="flex flex-wrap items-center gap-4 text-sm mb-6">
                {[
                  { icon: Book, text: quiz.subject, color: '#58cc02', bg: '#e5f8e7' },
                  { icon: GraduationCap, text: quiz.grade ? `السنة ${quiz.grade}` : 'غير محدد', color: '#1cb0f6', bg: '#e6f8ff' },
                  { icon: FileType, text: quiz.type || 'عام', color: '#ce82ff', bg: '#f9e8ff' }
                ].map(({ icon: Icon, text, color, bg }, index) => (
                  <div key={index} className="flex items-center rounded-full px-3 py-1" style={{ backgroundColor: bg }}>
                    <Icon className="h-4 w-4 mr-2" style={{ color }} />
                    <span style={{ color }} className="font-medium">{text}</span>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={() => onPlayQuiz(quiz)}
              className="w-full bg-[#58cc02] text-white rounded-2xl py-3 px-8 hover:bg-[#46a302] transition-colors duration-300 font-bold text-lg shadow-md flex items-center justify-center"
            >
              <PlayCircle className="h-6 w-6 mr-2" />
              <span>ابدأ التمرين</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const QuizBottomBar = ({ quizState, modalContentRef }) => {
  const bottomBarRef = useRef(null);
  const heartContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (modalContentRef && modalContentRef.current) {
      const scrollTarget = modalContentRef.current.scrollHeight - 200;
      modalContentRef.current.scrollTo({
        top: scrollTarget,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (quizState.removingHeartIndex !== null && heartContainerRef.current) {
      heartContainerRef.current.classList.add('animate__animated', 'animate__shakeX');
      const animationEndHandler = () => {
        heartContainerRef.current.classList.remove('animate__animated', 'animate__shakeX');
      };
      heartContainerRef.current.addEventListener('animationend', animationEndHandler);
      return () => {
        if (heartContainerRef.current) {
          heartContainerRef.current.removeEventListener('animationend', animationEndHandler);
        }
      };
    }
  }, [quizState.removingHeartIndex]);

  const renderProgressBar = () => {
   // const percentage = quizState.percentageCorrect;
    const percentage = Math.round((quizState.currentQuestionIndex / quizState.numberOfQuestions) * 100);
    return (
      <div className="w-full bg-[#e5e5e5] rounded-2xl h-6 relative overflow-hidden">
        <div
          className="bg-[#58cc02] h-full transition-all duration-300 ease-in-out"
          style={{ width: `${percentage}%` }}
        ></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className={`text-sm font-bold ${percentage > 50 ? 'text-white' : 'text-[#3c3c3c]'}`}>
            {percentage}%
          </span>
        </div>
      </div>
    );
  };

  const renderQuizStatus = () => (
    <div className="flex flex-col space-y-4">
      {quizState.isCurrentQuestionCompleted && (
        <button
          onClick={() => {
            quizState.handleNextQuestion();
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          }}
          className="w-full bg-[#58cc02] hover:bg-[#46a302] text-white rounded-2xl py-4 animate__animated animate__slow animate__pulse animate__infinite  px-6 font-bold text-xl shadow-lg transition-colors duration-300 flex items-center justify-center"
        >
          <span>التالي</span>
          <ArrowRight className="h-6 w-6 ml-2" />
        </button>
      )}
      <div className="flex justify-between items-center">
        <div ref={heartContainerRef} className="flex items-center bg-red-50 px-3 py-1 rounded-full">
          <Heart className="w-5 h-5 text-red-500 mr-1" fill="currentColor" />
          <span className="font-bold text-base text-gray-700">{quizState.hearts}</span>
        </div>
        <div className="font-bold text-base text-gray-700">
          {quizState.correctCount}/{quizState.totalBlanks} صحيحة
        </div>
      </div>
      {renderProgressBar()}
    </div>
  );

  const renderGameOver = () => (
    <div className="text-center">
      <div className="flex items-center justify-center space-x-4 mb-6">
        <span className="text-6xl font-bold text-[#58cc02]">{quizState.percentageCorrect}%</span>
        <Trophy className="w-12 h-12 text-[#ffc800]" />
      </div>
      <p className="text-lg text-gray-600 mb-6">
        {quizState.correctCount}/{quizState.totalCount} إجابات صحيحة
      </p>
      <button
        onClick={quizState.handleRestart}
        className="w-full bg-[#58cc02] hover:bg-[#46a302] text-white rounded-2xl py-4 px-6 font-bold text-xl shadow-lg transition-colors duration-300 flex items-center justify-center"
      >
        <RefreshCw className="h-6 w-6 mr-2" />
        <span>العب مجددًا</span>
      </button>
    </div>
  );

  return (
    <div 
      ref={bottomBarRef}
      className="sticky bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-10 transition-all duration-300 ease-in-out p-4"
    >
      {!quizState.isGameOver ? renderQuizStatus() : renderGameOver()}
    </div>
  );
};

const useQuizState = (quizData, onGameEnd) => {
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [answeredBlanks, setAnsweredBlanks] = useState(new Set());
  const [hearts, setHearts] = useState(10);
  const [isGameOver, setIsGameOver] = useState(false);
  const [percentageCorrect, setPercentageCorrect] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [removingHeartIndex, setRemovingHeartIndex] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isCurrentQuestionCompleted, setIsCurrentQuestionCompleted] = useState(false);
  const [hasAnsweredFirstBlank, setHasAnsweredFirstBlank] = useState(false);
  const hasClickedFirstNext = useRef(false);
  const [isTranslated, setIsTranslated] = useState(false);

  const [questionsProgress, setQuestionProgress] = useState({});


  const toggleTranslation = useCallback(() => {
    console.log('toggleTranslation');
    setIsTranslated(prev => !prev);
  }, []);

  const totalBlanks = quizData?.questions?.reduce((total, question) => 
    total + Object.keys(question.answer.blanks).length, 0
  ) || 0;

  const calculateCurrentQuestionCompletion = useCallback(() => {
    if (!quizData?.questions) return false;
    const currentQuestion = quizData.questions[currentQuestionIndex];
    const currentQuestionBlanksCount = Object.keys(currentQuestion.answer.blanks).length;
    const answeredBlanksInCurrentQuestion = [...answeredBlanks].filter(blankId => 
      blankId.startsWith(`${currentQuestionIndex}-`)
    ).length;
    return answeredBlanksInCurrentQuestion === currentQuestionBlanksCount;
  }, [quizData, currentQuestionIndex, answeredBlanks]);

  useEffect(() => {
    setIsCurrentQuestionCompleted(calculateCurrentQuestionCompletion());
  }, [answeredBlanks, calculateCurrentQuestionCompletion]);

  const handleSelectChange = useCallback((event, qIndex, key, correctValue) => {
    const select = event.target;
    const isCorrect = select.value === correctValue;
    select.style.borderColor = isCorrect ? '#58cc02' : '#ff4b4b';

    const selectedValue = event.target.value;
    const tooltipId = `tooltip-${qIndex}-${key}`;
    const tooltip = document.getElementById(tooltipId);

    if (selectedValue !== correctValue) {
      console.log('incorrect');
      // Show tooltip for incorrect answer
      window.showTooltip(`${qIndex}-${key}`);
      tooltip.style.opacity = '1';
      tooltip.style.pointerEvents = 'auto';
    } else {
      console.log('correct');
      window.hideTooltip(`${qIndex}-${key}`);
      // Hide tooltip for correct answer
      tooltip.style.opacity = '0';
      tooltip.style.pointerEvents = 'none';
    }
    const blankId = `${qIndex}-${key}`;
    setAnsweredBlanks(prev => {
      const newAnsweredBlanks = new Set(prev).add(blankId);
      
      if (!hasAnsweredFirstBlank && newAnsweredBlanks.size === 1) {
        setHasAnsweredFirstBlank(true);
        window.plausible('answer_first_blank', {
          props: {
            quizId: quizData.id,
            quizName: quizData.name
          }
        });
      }
      
      return newAnsweredBlanks;
    });

    setCorrectAnswers(prev => {
      const newCorrectAnswers = { ...prev, [blankId]: isCorrect };
      const totalCorrect = Object.values(newCorrectAnswers).filter(Boolean).length;
   // Update progress for the current question
      setQuestionProgress(prevProgress => {
        const questionBlanks = Object.keys(quizData.questions[qIndex].answer.blanks);
        const questionCorrect = questionBlanks.filter(blankKey => 
          newCorrectAnswers[`${qIndex}-${blankKey}`]
        ).length;
        return {
          ...prevProgress,
          [qIndex]: questionCorrect / questionBlanks.length
        };
      });

      setCorrectCount(totalCorrect);
      setTotalCount(totalBlanks);
      setPercentageCorrect(Math.round((totalCorrect / totalBlanks) * 100));

      if (!isGameOver && totalCorrect === totalBlanks) {
        setIsGameOver(true);
        onGameEnd(totalCorrect, totalBlanks);
      }

      setHearts(prevHearts => {
        let newHearts = Math.max(prevHearts - (isCorrect ? 0 : 1), 0);

        if (!isGameOver && newHearts === 0) {
          setIsGameOver(true);
          onGameEnd(totalCorrect, totalBlanks);
        }

        if (newHearts < prevHearts) {
          setRemovingHeartIndex(prevHearts - 1);
          setTimeout(() => setRemovingHeartIndex(null), 2000);
        }

        return newHearts;
      });

      return newCorrectAnswers;
    });
  }, [isGameOver, onGameEnd, totalBlanks, hasAnsweredFirstBlank, quizData]);

  const handleNextQuestion = useCallback(() => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      if (currentQuestionIndex === 0 && !hasClickedFirstNext.current) {
        window.plausible('reach_second_question', {
          props: {
            quizId: quizData.id,
            quizName: quizData.name
          }
        });
        hasClickedFirstNext.current = true;
      }
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setIsCurrentQuestionCompleted(false);
    } else {
      setIsGameOver(true);
      onGameEnd(correctCount, totalBlanks);
    }
  }, [currentQuestionIndex, quizData, correctCount, totalBlanks, onGameEnd]);

  const handleRestart = useCallback(() => {
    setHearts(10);
    setIsGameOver(false);
    setPercentageCorrect(0);
    setCorrectCount(0);
    setTotalCount(0);
    setCorrectAnswers({});
    setAnsweredBlanks(new Set());
    setCurrentQuestionIndex(0);
    setIsCurrentQuestionCompleted(false);
    setHasAnsweredFirstBlank(false);
    hasClickedFirstNext.current = false;

    document.querySelectorAll('select').forEach(select => {
      select.value = '';
      select.style.borderColor = '#e5e5e5';
    });
    window.plausible('restart_quiz');
  }, []);

  useEffect(() => {
    window.handleSelectChange = handleSelectChange;
  }, [handleSelectChange]);

  return {
    correctAnswers,
    answeredBlanks,
    hearts,
    isGameOver,
    totalBlanks,
    questionsProgress,
    percentageCorrect,
    correctCount,
    totalCount,
    removingHeartIndex,
    handleSelectChange,
    handleRestart,
    currentQuestionIndex,
    isCurrentQuestionCompleted,
    handleNextQuestion,
    isTranslated,
    toggleTranslation,
    numberOfQuestions : quizData.questions.length,
    hasAnsweredFirstBlank
  };
};


const Quiz = ({ quizData, quizState }) => {
  return (
    <div className="space-y-6 sm:space-y-8 md:space-y-10" dir="rtl">
      
      {quizData.questions.slice(0, quizState.currentQuestionIndex + 1).map((question, index) => (
        <Question
          key={index}
          question={question}
          index={index}
          isGameOver={quizState.isGameOver}
          onSelectChange={quizState.handleSelectChange}
          isTranslated={quizState.isTranslated}
          onToggleTranslation={quizState.toggleTranslation}
          questionProgress={quizState.questionsProgress[index]}

        />
      ))}
    </div>
  );
};


const QuizModal = ({ quiz, onClose, handleGameEnd }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalContentRef = useRef(null);

  const quizState = useQuizState(quiz?.quizData, (correct, total) => {
    handleGameEnd(correct, total, quiz?.id, quiz?.name);
  });

  useEffect(() => {
    if (quiz) {
      setIsVisible(true);
      document.body.style.overflow = 'hidden';
    }
    return () => { document.body.style.overflow = 'visible'; };
  }, [quiz]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  if (!quiz) return null;

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      onClick={handleClose}
    >
      
      <div
        ref={modalContentRef}
        className={`bg-white w-full h-full overflow-auto transition-transform duration-300 ${isVisible ? 'scale-100' : 'scale-95'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="max-w-4xl mx-auto px-4 py-6 sm:px-6 sm:py-8 relative flex flex-col h-full">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-[#4b4b4b]">{quiz.name}</h2>
            <button
              onClick={handleClose}
              className="w-10 h-10 flex items-center justify-center bg-[#ff4b4b] text-white rounded-full hover:bg-[#ff3333] transition-colors duration-300 flex-shrink-0"
              aria-label="Close"
            >
              <X size={24} />
            </button>
          </div>
          <QuizRulesExplanation  />
          
          <Quiz
            quizData={quiz.quizData}
            quizState={quizState}
          />
          <QuizBottomBar quizState={quizState} modalContentRef={modalContentRef} />
        </div>
      </div>
    </div>
  );
};


const Home = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [savedPercentages, setSavedPercentages] = useState({});
  const [searchParams] = useSearchParams();

  const handleGameEnd = useCallback((correct, total, quizId, quizName) => {
    const percentage = Math.round((correct / total) * 100);

    setSavedPercentages(prevPercentages => {
      const updatedPercentages = {
        ...prevPercentages,
        [quizId]: percentage
      };
      localStorage.setItem('quizPercentages', JSON.stringify(updatedPercentages));
      return updatedPercentages;
    });

    window.plausible('finish_quiz', {
      props: {
        quizId: quizId,
        quizName: quizName,
        percentage: percentage,
        totalQuestions: total
      }
    });
  }, []);



  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const quizzesQuery = query(
          collection(db, 'quizzes'),
          where('isPublished', '==', true)
        );
        const quizzesSnapshot = await getDocs(quizzesQuery);
        const quizzesList = quizzesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          quizData: JSON.parse(doc.data().quizJson)
        }));

        setQuizzes(quizzesList.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()));
        setSavedPercentages(JSON.parse(localStorage.getItem('quizPercentages')) || {});
      } catch (err) {
        console.error('Error fetching quizzes:', err);
        setError('Failed to load quizzes. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, []);

  useEffect(() => {
    const fetchQuizFromUrl = async () => {
      const quizId = searchParams.get('quizId');
      if (quizId) {
        try {
          const quizDoc = await getDoc(doc(db, 'quizzes', quizId));
          if (quizDoc.exists()) {
            const quizData = quizDoc.data();
            setSelectedQuiz({
              id: quizDoc.id,
              ...quizData,
              quizData: JSON.parse(quizData.quizJson)
            });
          } else {
            setError('Quiz not found.');
          }
        } catch (err) {
          console.error('Error fetching quiz:', err);
          setError('Failed to load the quiz. Please try again later.');
        }
      }
    };

    fetchQuizFromUrl();
  }, [searchParams]);

  const handlePlayQuiz = (quiz) => {
    setSelectedQuiz(quiz);
    window.plausible('play_quiz', { props: { quizId: quiz.id, quizName: quiz.name } });
  };

  const handleCloseModal = () => {
    setSelectedQuiz(null);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('quizId');
    window.history.replaceState({}, '', `${window.location.pathname}?${newSearchParams}`);
  };

  if (loading) return <div className="text-center py-20 text-2xl">جاري التحميل...</div>;
  if (error) return <div className="text-center py-20 text-2xl text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-12" dir="rtl">
      <header className="bg-[#ff9900] text-white p-6 rounded-lg relative overflow-hidden mb-8">
        <div className="absolute top-0 right-0 w-24 h-24 bg-[#e68a00] rounded-full -mr-8 -mt-8"></div>
        <div className="relative z-10">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 leading-tight">راجع دروسك في 10 دقائق يوميّا</h1>
          <p className="text-xl md:text-2xl">أنجز تمارين في شكل كويزات بأسلوب ممتع، خفيف و بأكثر فائدة.</p>
        </div>
        <Book className="absolute bottom-2 right-2 w-12 h-12 text-[#e68a00]" />
      </header>
      <QuizList quizzes={quizzes} onPlayQuiz={handlePlayQuiz} savedPercentages={savedPercentages} />
      {selectedQuiz && (
        <QuizModal
          quiz={selectedQuiz}
          onClose={handleCloseModal}
          handleGameEnd={handleGameEnd}
          />
      )}
    </div>
  );
};

export default Home;