// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-fQXVCeooRjcWpIkHf0IplMb5HPis0HE",
  authDomain: "class-clash.firebaseapp.com",
  projectId: "class-clash",
  storageBucket: "class-clash.appspot.com",
  messagingSenderId: "273034871041",
  appId: "1:273034871041:web:3675cd48349c61d01b9b00",
  measurementId: "G-H50W6KT1CS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getFirestore(app, "(default)");

/* 
async function initializeDatabase() {
      const gradesSubjectsAndLessons = {
        "Grade 1": {
          "Math": {
            "Counting": "Learn to count from 1 to 100...",
            "Basic Addition": "Introduction to adding numbers..."
          },
          "Science": {
            "Plants": "Learn about different parts of plants...",
            "Animals": "Explore different types of animals..."
          }
        },
        "Grade 2": {
          "Math": {
            "Subtraction": "Learn how to subtract numbers...",
            "Shapes": "Explore basic geometric shapes..."
          },
          "Science": {
            "Weather": "Learn about different weather conditions...",
            "Human Body": "Explore basic parts of the human body..."
          }
        }
      };
    
      try {
        for (const [gradeName, subjects] of Object.entries(gradesSubjectsAndLessons)) {
          // Add grade
          const gradeRef = await addDoc(collection(db, 'grades'), { name: gradeName });
    
          for (const [subjectName, lessons] of Object.entries(subjects)) {
            // Add subject
            const subjectRef = await addDoc(collection(db, 'subjects'), { 
              name: subjectName,
              gradeId: gradeRef.id
            });
    
            for (const [lessonName, lessonContent] of Object.entries(lessons)) {
              // Add lesson
              await addDoc(collection(db, 'lessons'), {
                name: lessonName,
                content: lessonContent,
                subjectId: subjectRef.id
              });
            }
          }
        }
    
        console.log('Database initialized successfully');
      } catch (error) {
        console.error('Error initializing database:', error);
      }
    }

*/ 
