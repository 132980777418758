import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Logo from './assets/images/logo.png';
import './App.scss';
import 'animate.css';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100 py-6">
        <div className="container mx-auto ">
          <header className="mb-8">
            <div className="text-3xl font-bold text-center flex items-center justify-center">
              <img src={Logo} alt="Class Clash Logo" className="h-12 mr-3" />

            </div>
          </header>

          {/* <nav className="mb-6">
            <ul className="flex justify-center space-x-4">
              <li><Link to="/" className="text-blue-500 hover:text-blue-700">Home</Link></li>
              <li><Link to="/dashboard" className="text-blue-500 hover:text-blue-700">Dashboard</Link></li>
            </ul>
          </nav> */}

          <div className="max-w-4xl mx-auto">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;