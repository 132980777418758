const ToggleSwitch = ({ isOn, onToggle, label, image = 'https://example.com/default-image.png' }) => {
    return (
      <div className="flex items-center">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="hidden"
              checked={isOn}
              onChange={onToggle}
            />
            <div className={`w-10 h-6 bg-gray-300 rounded-full shadow-inner transition-colors duration-300 ease-in-out ${isOn ? 'bg-green-400' : ''}`}></div>
            <div className={`absolute w-4 h-4 bg-white rounded-full shadow inset-y-1 left-1 transition-transform duration-300 ease-in-out ${isOn ? 'transform translate-x-full' : ''}`}></div>
          </div>
          <div className="flex items-center mr-2">
            {image && <img src={image} alt={label || ''} className="w-6 h-6 mr-1" />}
            {label && <span className="text-sm font-medium text-gray-700">{label}</span>}
          </div>
        </label>
      </div>
    );
  };

  export default ToggleSwitch;
